/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

function importAll(r) {
  return r.keys().map(r);
}

import "../vendor/bootstrap/css/bootstrap.css"
import "../vendor/font-awesome/css/fontawesome-all.css"
import "../vendor/owl.carousel/assets/owl.carousel.css"
import "../vendor/owl.carousel/assets/owl.theme.default.css"
import "../css/theme.css"
import "../css/theme-elements.css"
import "../css/skins/default.css"
import "../css/custom.css"
import "../css/refwell.sass"

import "../vendor/jquery.appear/jquery.appear.js"
import "../vendor/jquery.easing/jquery.easing.js"
import "../vendor/bootstrap/js/bootstrap.js"
import "../vendor/common/common.js"
import "../vendor/owl.carousel/owl.carousel.js"
import "../js/theme.js"
import "../js/theme.init.js"

import '../img/blank.gif'
const images1 = importAll(require.context('../img/rw-custom', false, /\.(png|jpe?g|svg)$/));
const images2 = importAll(require.context('../img/rw-logos', false, /\.(png|jpe?g|svg)$/));
